import { graphql, useStaticQuery } from "gatsby"

export default function useSiteMetadata() {
  const data = useStaticQuery(graphql`
    query getMetadata {
      site {
        siteMetadata {
          title
          mainHeader
          subHeader
          description
          repoUrl
          infoData {
            contact {
              email
              github_handle
              twitter_handle
              linkedin_handle
              linkedin_url
              calendly_url
            }
            description
            content {
              talks {
                title
                url
                group
              }
              writing {
                title
                url
              }
              coaching {
                title
                url
              }
            }
          }
        }
      }
    }
  `)
  return data.site.siteMetadata
}
