import React from "react"
import { openInNewTab } from "../utilities/event-handlers"

export default function NewTabLink(props) {
  return (
    <a
      key={props.url}
      href="/"
      className="navbar-item"
      onClick={() => openInNewTab(props.url)}
    >
      {props.group ? `${props.title} (${props.group})` : props.title}
    </a>
  )
}
