import React from "react"
import Header from "./Header"
import Helmet from "react-helmet"
import useSiteMetadata from "../static_queries/useSiteMetadata"
import { openInNewTab } from "../utilities/event-handlers"

export default function Layout(props) {
  const { title, description, mainHeader, infoData } = useSiteMetadata()

  return (
    <div>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      <Header page={props.page} title={mainHeader} infoData={infoData} />

      <section className="hero is-bold is-fullheight has-bg-image">
        <div className="hero-body">
          <div className="container">
            <h1 className="title">Hello! I'm Devon Mobley.</h1>
            <h2 className="subtitle is-marginless">
              I am a Technologist, Entrepreneur, Consultant, and Startup
              Advisor.
            </h2>
            <h2 className="subtitle">Let's do something great together...</h2>
            <button
              className="button is-primary"
              onClick={() => openInNewTab(infoData.contact.calendly_url)}
            >
              Schedule a Call
            </button>
          </div>
        </div>
      </section>
      {props.children}
      <footer className="site-footer">
        <p>
          &copy; {new Date().getFullYear()} Devbot Technology and Consulting
        </p>
      </footer>
    </div>
  )
}
