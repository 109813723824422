import React from "react"
import { Link } from "gatsby"
import { openInNewTab } from "../utilities/event-handlers"
import Dropdown from "./Dropdown"
import NewTabLink from "./NewTabLink"

export default function Header(props) {
  const { infoData } = props
  let keyIncrementor = 0

  return (
    <header>
      <nav className="navbar" role="navigation" aria-label="main navigation">
        <div className="navbar-brand"></div>
        <div className="navbar-menu is-active">
          <div className="navbar-start">
            <Link className="navbar-item" to="/">
              <h5 className="title is-5">{props.title}</h5>
            </Link>
            <Dropdown display="Writing">
              {infoData.content.writing.map(talk => {
                keyIncrementor += 1
                return <NewTabLink {...talk} key={keyIncrementor}></NewTabLink>
              })}
            </Dropdown>
            <Dropdown display="Talks">
              {infoData.content.talks.map(talk => {
                keyIncrementor += 1
                return <NewTabLink {...talk} key={keyIncrementor}></NewTabLink>
              })}
            </Dropdown>
            <Dropdown display="Coaching">
              {infoData.content.coaching.map(coachingProduct => {
                keyIncrementor += 1
                return (
                  <NewTabLink
                    {...coachingProduct}
                    key={keyIncrementor}
                  ></NewTabLink>
                )
              })}
            </Dropdown>
          </div>
          <div className="navbar-end">
            <div className="navbar-item">
              <button
                className="button is-primary"
                onClick={() => openInNewTab(infoData.contact.calendly_url)}
              >
                Schedule a Call
              </button>
            </div>
            <div className="navbar-item">
              <div className="field is-grouped">
                <p className="control">
                  <a
                    href={`${infoData.contact.linkedin_url}`}
                    className="button is-linkedin"
                  >
                    <span className="icon">
                      <i className="fab fa-linkedin fa-lg"></i>
                    </span>
                  </a>
                </p>
                <p className="control">
                  <a
                    className="button is-dark"
                    href={`mailto:${infoData.contact.email}`}
                  >
                    <span className="icon">
                      <i className="fas fa-envelope"></i>
                    </span>
                  </a>
                </p>
                <p className="control">
                  <a
                    href={`https://github.com/${infoData.contact.github_handle}`}
                    className="button is-github"
                  >
                    <span className="icon">
                      <i className="fab fa-github fa-lg"></i>
                    </span>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>
  )
}
