import React, { useState } from "react"
import { useWindowSize } from "../hooks/event-hooks"

export default function Dropdown(props) {
  const size = useWindowSize()
  const [isClicked, setIsClicked] = useState(false)
  let isHoverable = true

  if (size.width < 1024) {
    isHoverable = false
    // setIsMobile(true)
  }
  return (
    <div
      className={`navbar-item has-dropdown ${
        isHoverable ? "is-hoverable" : ""
      }`}
    >
      <span className="navbar-link" onClick={() => setIsClicked(!isClicked)}>
        {props.display}
      </span>
      <div
        className={`navbar-dropdown ${
          isHoverable ? "" : isClicked ? "" : "is-hidden"
        }`}
      >
        {props.children}
      </div>
    </div>
  )
}
